import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { usePageStartdataCs } from '../hooks/cs/page-start.metadata';
import { usePageStartdataEn } from '../hooks/en/page-start.metadata.en';

export function PageStartMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = usePageStartdataCs();
  } else {
    data = usePageStartdataEn();
  }
  return data;
}
